import { Router } from "aurelia-router";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { autoinject } from "aurelia-dependency-injection";
import { SingletonService } from "singleton";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { YapilyPaymentAuthRequest } from "components/models/YapilyModels/YapilyPaymentAuthRequests";
import { YapilyPaymentRequestModel } from "components/models/YapilyModels/YapilyPaymentRequestModel";
import { YapilyAmountModel } from "components/models/YapilyModels/YapilyAmountModel";
import { YapilyPayeeModel } from "components/models/YapilyModels/YapilyPayeeModel";
import { YapilyPaymentsDetailsModel } from "components/models/YapilyModels/YapilyPaymentsDetailsModel";
import { YapilyBanksDetailsModel } from "components/models/YapilyModels/YapilyBanksDetailsModel";
import { SubscriptionHttpClient } from "http_clients/SubscriptionHttpClient";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { AuthService } from "aurelia-auth";
import { json } from "aurelia-fetch-client";
import { YapilyAccountIdentificationModel } from "components/models/YapilyModels/YapilyAccountIdentificationModel";
import { User } from "components/models/UserModel";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class subscriptionSuccess {
  private institution: YapilyBanksDetailsModel;
  private accounts: any;
  private paymentsAuthInformation: YapilyPaymentRequestModel;
  private received_consent: string;
  private userUuid: string;
  private paymentsDetails: YapilyPaymentsDetailsModel;
  private isLoading: boolean = false;
  private me: User;
  private payerName: string;
  private iban: string;

  constructor(
    private singleton: SingletonService,
    private yapilyHttpClient: YapilyHttpClient,
    private subscriptionHttpClient: SubscriptionHttpClient,
    private userHttpClient: UsersHttpClients,
    private authService: AuthService,
    private router: Router,
    private ea: EventAggregator
  ) {}

  activate(params) {
    try {
      if (
        params["error-description"] != undefined ||
        params.consent != undefined
      ) {
        console.log("frist");
        this.router.navigateToRoute("subscription_failure");
      } else {
        console.log("two");
        let application_user_id = params["error-description"];
        console.log(application_user_id);
        this.institution = params.institution;
        this.received_consent = params.consent;
        this.userUuid = params.users;
        this.iban = this.singleton.getIban();
        this.payerName = this.singleton.getPayerName();
        console.log(this.payerName);
        console.log(this.iban);
        console.log(params);
        console.log("started");

        this.createSubscriptionPayments();
      }
    } catch (e) {
      if (this.me.role == "admin") {
        alert(e);
      }
      console.log(e);
    }
  }

  attached() {}

  async getMe() {
    const rep = await this.authService.getMe();
    const repa = await this.authService.getTokenPayload();
  }

  async createSubscriptionPayments() {
    try {
      console.log("started1");
      this.isLoading = true;
      this.me = this.authService.getMe();
      let httpRequest = await this.subscriptionHttpClient.fetch(
        "/subscription/execute",
        {
          method: "POST",
          body: JSON.stringify({
            iban: this.iban,
            name: this.payerName,
          }),
          headers: {
            consent: this.received_consent,
          },
        }
      );
      let is200ok = await checkResponseStatus(httpRequest);
      this.paymentsDetails = await is200ok.json();

      /* call event */
      this.ea.publish("me.profile.subscription.update");

      setTimeout(() => this.router.navigateToRoute("home"), 15000);
    } catch (err) {
      console.log(err);
    }
  }
}
